<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">编辑广告屏信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="80">
      <FormItem prop="poleId" label="所属灯杆">
        <PoleSelect v-model="poleInfo" />
      </FormItem>
      <FormItem prop="name" label="名称">
        <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
      <FormItem prop="type" label="接入方式">
        <Select v-model="form.connectType">
          <template v-for="(item, idx) in connectTypes">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="code" label="设备ID">
        <Input ref="code" type="text" v-model="form.code" placeholder="请输入设备ID">
        </Input>
      </FormItem>
      <FormItem prop="width" label="屏宽度">
        <Input ref="width" type="text" number v-model="form.width" placeholder="请输入屏宽度">
        </Input>
      </FormItem>
      <FormItem prop="height" label="屏高度">
        <Input ref="height" type="text" number v-model="form.height" placeholder="请输入屏高度">
        </Input>
      </FormItem>
      <FormItem prop="buildDate" label="安装日期">
        <DatePicker ref="buildDate" type="date" format="yyyy-MM-dd" v-model="form.buildDate" placeholder="请选择安装日期" :editable="false"></DatePicker>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
import PoleSelect from '../../common/poleSelect/Index'
export default {
  name: 'ModalItemEdit',
  components:{
    PoleSelect,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    connectTypes: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      accounts: [],
      form: {
        id: 0,
        poleId: '',
        name: '',
        connectType: 0,
        code: '',
        width: 192,
        height: 320,
        buildDate: '',
      },
      poleInfo: {id: '', name: ''},
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // topCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
        // location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        // monitorCode: { required: true, type: 'string', max: 20, message: '编码不能为空且长度必须少于20个字符', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        // console.log('modal open', this.item, this.selectedDevices)
        let poleName = '';
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.poleId = this.item.data.poleId;
          this.form.name = this.item.data.name;
          this.form.connectType = this.item.data.connectType;
          this.form.code = this.item.data.code;
          this.form.buildDate = this.item.data.buildDate;
          this.form.width = this.item.data.width || 192;
          this.form.height = this.item.data.height || 320;
          poleName = this.item.data.poleName;
        }else{
          this.form.id = '';
          this.form.poleId = this.item.data.poleId;
          this.form.name = '';
          this.form.connectType = 1;
          this.form.code = '';
          this.form.buildDate = new Date().format('yyyy-MM-dd');
          this.form.width = 192;
          this.form.height = 320;
        }
        this.$set(this, 'poleInfo', {id: this.form.poleId, name: poleName});
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  computed: {
    ...mapState('group', ['selectedGroups', 'selectedDevices', 'poleSelectedNode']),
  },
  mounted: function(){
    // this.getProjects();
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      if (this.poleInfo.id.length == 0) {
        this.$Message.warning("请选择所在灯杆");
        return;
      }
      this.form.poleId = this.poleInfo.id;
      this.form.buildDate = new Date(this.form.buildDate).format('yyyy-MM-dd');
      this.$axios.post(`device/led/SaveLedInfo`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>